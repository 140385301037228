/* reset ------------------------------------------------------------------------------------------------ */

html {
  box-sizing: border-box;
  font-size: 16px;
  scroll-behavior: smooth;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

a:active {
  color: inherit;
  text-decoration: none;
}

a:visited {
  color: inherit;
  text-decoration: none;
}

/* variables ----------------------------------------------------------------------------------------*/
:root {
  --primary: #2890b0;
  --secondary: #0f495a;
  --accent: #e84300;
  --light: rgb(255, 255, 255);
  --dark: rgb(29, 29, 29);
  --mainFont: "Montserrat", sans-serif;
  --italic: poppins-italic;
}

/* root -------------------------------------------------------------------------------------------- */
body {
  font-family: var(--mainFont);
}

#root {
  min-height: 100vh;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  margin-top: 3rem;
}

button {
  padding: 1.2rem 3rem;
  border: 0px;
  border-radius: 15px;
  font-size: 1rem;
  font-weight: 800;
  transition: all 0.3s ease-in-out;
  width: 100%;
}

.btn-sm {
  padding: 0.6rem 1rem;
  font-size: 0.8rem;
  max-width: 200px;
  background-color: var(--primary);
  color: white;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

button:hover {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.btn {
  background-color: var(--primary);
  color: white;
}

.btn-alert {
  background-color: var(--primary);
  color: var(--light);
}

.btn-cta {
  background-color: orange;
  color: var(--dark);
}

.btn:hover {
  background-color: var(--secondary);
  color: var(--light);
}

.btn-sm:hover {
  background-color: var(--secondary);
  color: var(--light);
}

.btn-alert:hover {
  background-color: var(--secondary);
  color: var(--light);
}

.btn-cta:hover {
  background-color: rgb(255, 0, 0);
  color: var(--light);
}

.btn_sm {
  font-size: 1rem;
  padding: 1rem 1rem;
}

.idle,
.idle:hover {
  background-color: rgb(253, 114, 1);
}

.lds-ring {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  margin: 5px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.italic-font {
  font-family: var(--italic);
}

.alt-font {
  color: orange;
}

h1,
h2,
h3,
p {
  margin-bottom: 1em;
}

h1 {
  font-size: 2.5rem;
  font-weight: 900;
}

h2 {
  font-size: 2rem;
  font-weight: 800;
}

h3 {
  font-size: 1.3rem;
  font-weight: 700;
}

.alert {
  margin: 0 auto;
  max-width: 500px;
  text-align: center;
  color: red;
}

a:hover {
  color: var(--accent);
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.w100 {
  width: 100%;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
  gap: 30px;
  align-items: center;
}

.marginTop {
  margin-top: 2rem;
}

.marginTop2 {
  margin-top: 4rem;
}

.alert__box {
  background-color: #ff9341;
  padding: 0.2rem 2rem 0.2rem 0.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: fit-content;
}

.alert__box p {
  margin: 0px;
  color: rgb(0, 0, 0);
  font-weight: 500;
  font-size: 0.8rem;
}

.alert__box p::before {
  content: "|";
  margin-right: 1rem;
}

.center {
  margin: 0 auto;
}

.column {
  flex-direction: column;
}

.pl::after {
  content: " zł";
}

.us::before {
  content: "$";
}

.gb::before {
  content: "£";
}

.eu::after {
  content: " €";
}

.finished-pl::before {
  content: "Zakończone";
}

.hold-pl::before {
  content: "Oczekujące";
}

.payed-pl::before {
  content: "Opłacone";
}

.placed-pl::before {
  content: "Złożone";
}

.error_msg {
  max-width: 1600px;
  font-size: 1.3rem;
  font-weight: 500;
  margin: 0 auto;
  text-align: center;
  color: green;
}

.main {
  padding: 1rem;
}

.inputErr {
  display: block;
  color: red;
  margin-top: -15px;
  margin-bottom: 1rem;
  font-size: 0.8rem;
}

.msg {
  display: block;
  margin-top: -15px;
  margin-bottom: 1rem;
  font-size: 0.8rem;
}

.green {
  color: green;
}

.red {
  color: red;
}

input:invalid + .inputErr {
  display: block;
}

.error_select {
  padding-top: 10px;
}

.icon-inline {
  display: inline-block;
  border: 0px;
  border-radius: 15px;
  font-size: 1.3rem;
  font-weight: 800;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
