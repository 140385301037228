/* admin area --------------------------------------------------------------- */

.adminArea {
  width: 100%;
  max-width: 1600px;
  margin: 1rem auto 3rem auto;
}

.adminArea__main {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.adminMenu {
  padding: 0.2rem 2rem 1rem 2rem;
  margin-bottom: 1rem;
  font-size: 1.4rem;
  border-radius: 20px;
}

.adminMenu__list {
  display: flex;
  gap: 2rem;
}

.adminMenu__item {
  background-color: white;
  color: var(--primary);
  padding-top: 0.9rem;
  padding-bottom: 1.1rem;
  padding-right: 1.5rem;
  margin-bottom: -1.1rem;
  width: 100%;
  font-weight: 700;
  transition: all 0.3s ease;
  border-radius: 20px;
}

.adminMenu__item:hover {
  cursor: pointer;
  color: var(--dark);
}

.adminMenu__item.active {
  color: var(--dark);
}

.adminArea__module {
  flex-basis: 100%;
  padding: 1rem 2rem 1rem 2rem;
  min-height: 60vh;
}

.adminOrders__row {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  gap: 0.5rem;
  min-height: 45px;
  border: 1px solid #ccd;
  padding-left: 1rem;
  padding-right: 1rem;
}

.adminProducts__row {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  gap: 0.5rem;
  min-height: 45px;
  border: 1px solid #ccd;
  padding-left: 1rem;
  padding-right: 1rem;
}

.adminOrders__row:hover {
  background-color: rgb(241, 241, 241);
}

.adminProducts__row:hover {
  background-color: rgb(241, 241, 241);
}

.mark-unread {
  position: absolute;
  left: -15px;
  top: 18px;
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
}

.mark-read {
  display: none;
}

.mark-ref {
  position: absolute;
  left: -25px;
  top: 18px;
  width: 8px;
  height: 8px;
  background-color: rgb(0, 80, 133);
  border-radius: 50%;
}

.mark-no-ref {
  display: none;
}

.mark-inv {
  position: absolute;
  left: -25px;
  top: 18px;
  width: 8px;
  height: 8px;
  background-color: rgb(0, 133, 11);
  border-radius: 50%;
}

.mark-no-inv {
  display: none;
}

.adminOrders__col.unread {
  color: red;
}

.adminOrders__col.id {
  width: 5%;
  min-width: 5%;
  font-weight: 800;
}

.adminOrders__col.email {
  width: 12%;
  min-width: 12%;
  font-weight: 600;
  color: var(--primary);
}

.adminOrders__col.client {
  width: 12%;
  min-width: 12%;
}

.adminOrders__col.date {
  width: 5%;
  min-width: 5%;
}

.adminOrders__col.value {
  width: 8%;
  min-width: 8%;
  text-align: right;
  font-weight: 900;
  color: var(--primary);
}

.adminOrders__col.status {
  width: 140px;
  min-width: 10%;
  font-weight: 800;
  text-align: left;
}

.adminOrders__col.heading {
  font-weight: 800;
}

.adminOrders__col.placed::after {
  content: "Złożone";
  color: orange;
}

.adminOrders__col.COMPLETED::after {
  content: "Opłacone";
  color: rgb(113, 189, 0);
}

.adminOrders__col.FINISHED::after {
  content: "Dostarczone";
  color: rgb(113, 189, 0);
}

.adminOrders__col.PENDING::after {
  content: "Oczekujące";
  color: rgb(255, 187, 0);
}

.adminOrders__col.PREP::after {
  content: "Przygotowane";
  color: #003ab7;
}

.adminOrders__col.CANCELED::after {
  content: "Anulowane";
  color: hsl(0, 100%, 36%);
}

.adminOrders__col.ON_DELIVERY::after {
  content: "W doręczeniu";
  color: hsl(165, 59%, 29%);
}

.adminOrders__col.IN_TRANSIT::after {
  content: "Wysłane";
  color: hsl(300, 47%, 38%);
}

.adminOrders__col.payment-method {
  width: 6%;
  text-align: right;
}

.adminOrders__searchbar {
  justify-content: flex-start;
}

.adminOrders__searchbar .fieldBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.adminOrders__searchbar .fieldBox input,
select {
  height: 2.3rem;
  padding: 0.3rem 1rem;
  border-radius: 10px;
  border: 1px solid gray;
}

.adminOrders__searchbar .fieldBox-row {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 5px;
}

.adminOrders__searchbar .fieldBox-row input,
select {
  height: 2.3rem;
  padding: 0.3rem 1rem;
  border-radius: 10px;
  border: 1px solid gray;
}

.fieldBox-row button {
  height: 40px;
  padding: 0.2rem 3rem;
}

.order_controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;
  max-width: 700px;
}

.order_control {
  flex-basis: 100%;
  width: 100%;
}

.clientData__value {
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
}

.clientData__h {
  margin-bottom: 0;
  margin-top: 0.5rem;
  max-width: 700px;
}

.clientData__heading {
  margin-bottom: 0.5rem;
  font-weight: 700;
  font-size: 1.2rem;
}

.clientData {
  display: flex;
  gap: 3rem;
  max-width: 700px;
  margin-top: 2rem;
}

.clientData__data {
  min-width: 45%;
}

.adminOrdersItem_row {
  display: flex;
  justify-content: space-between;
  max-width: 700px;
}

.adminOrdersItem_row .a-name {
  width: 50%;
  font-weight: 700;
}

.adminOrdersItem_row .a-quantity {
  width: 20%;
  text-align: right;
}

.adminOrdersItem_row .a-value {
  width: 30%;
  text-align: right;
  font-weight: 900;
  color: var(--primary);
}

.adminOrdersItem_row__total {
  max-width: 700px;
  display: flex;
  margin-top: 2rem;
  border-top: 1px solid var(--primary);
  gap: 2rem;
  justify-content: flex-end;
  font-size: 1.3rem;
  font-weight: 800;
  color: var(--primary);
}

.adminOrdersItem_row__discount {
  max-width: 700px;
  display: flex;
  margin-top: 2rem;
  gap: 2rem;
  justify-content: flex-end;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--primary);
}

.adminOrdersItem_row__discount strong {
  font-weight: 800;
}

.adminOrdersSingle > div > .fieldBox {
  display: flex;
  gap: 3rem;
}

.statusChange {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  width: 200px;
}

.statusChange select {
  height: 2rem;
  width: 100%;
}

.statusChange button {
  height: 2rem;
  font-size: 1rem;
  padding: 0.3rem 0.5rem;
  font-weight: 500;
}

.statusChange button:hover {
  background-color: #333;
  color: #f9f9f9;
}

.note__entry {
  margin-bottom: 0.5rem;
  max-width: 700px;
}

.note__date {
  font-weight: 600;
}

.placed {
  font-weight: 700;
  color: orange;
}

.COMPLETED {
  font-weight: 700;
  color: rgb(113, 189, 0);
}

.FINISHED {
  font-weight: 700;
  color: rgb(113, 189, 0);
}

.PENDING {
  font-weight: 700;
  color: rgb(255, 187, 0);
}

.CANCELED {
  font-weight: 700;
  color: red;
}

.PREP {
  font-weight: 700;
  color: #0027d4;
}

.ON_DELIVERY {
  font-weight: 700;
  color: #16630e;
}

.IN_TRANSIT {
  font-weight: 700;
  color: #650d63;
}

.prodName {
  width: 15%;
  color: var(--secondary);
  text-align: left;
  font-weight: 800;
}

.prodNetto {
  width: 10%;
  color: var(--primary);
  text-align: right;
  font-weight: 700;
}

.prodVat {
  width: 5%;
  text-align: right;
}

.prodQty {
  width: 5%;
  color: orange;
  text-align: right;
  font-weight: 800;
}

.productForm .form__input input {
  margin-bottom: 2rem;
}

.editProduct__price-row {
  display: flex;
  gap: 1rem;
}

.editProduct__product .form {
  flex-basis: 60%;
}

.editProduct__product .form .form__input {
  width: 100%;
}

.editProduct__product .img {
  width: 50%;
}

.productForm {
  display: flex;
  gap: 2rem;
}

.productForm .left,
.right {
  width: 100%;
}

.editProduct__controlls {
  display: flex;
  gap: 4rem;
}

.editProduct__controlls a,
.editProduct__controlls button {
  width: 100%;
  flex-basis: 100%;
}

.userDate {
  width: 15%;
  color: var(--primary);
  text-align: right;
}

.userRole {
  width: 15%;
  text-align: right;
  font-weight: 700;
}

#user_form {
  margin: 0 0;
}

.adminDashboard__filter {
  max-width: 50%;
}

.adminDashboard__filter .adminOrders__searchbar {
  justify-content: flex-start;
}

.adminDashboard__value {
  margin-top: 4rem;
}

.adminDashboard__value h3,
h4 {
  margin-bottom: 1rem;
}

.adminDashboard__value h1 {
  color: var(--primary);
  margin-bottom: 1rem;
}

.adminDashboard__value h2 {
  color: var(--secondary);
}

.adminDashboard__valueInner {
  flex-basis: 100%;
}

.adminDashboard__orders {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.adminDashboard__newOrders {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
}

.adminDashboard__order {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
}

.adminDashboard__statusInner {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  max-width: 500px;
}

.adminDashboard__statusBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.adminDashboard__statusBox .Złożone {
  font-weight: 700;
  color: orange;
}

.adminDashboard__statusBox .Opłacone {
  font-weight: 700;
  color: rgb(91, 151, 0);
}

.adminDashboard__statusBox .Zakończone {
  font-weight: 700;
  color: rgb(11, 72, 163);
}

.adminDashboard__statusBox .Oczekujące {
  font-weight: 700;
  color: rgb(124, 0, 128);
}

.adminDashboard__statusBox .Anulowane {
  font-weight: 700;
  color: red;
}

.adminDashboard__status {
  flex-basis: 100%;
}

.fieldBox .btn {
  padding: 0.4rem 2rem;
}

.fieldBox {
  justify-content: flex-end;
}

.set-status {
  justify-content: flex-start;
}

.pagination {
  display: flex;
  flex-direction: column;
  background-color: lightgray;
  padding-left: 0.5rem;
}

.pagination-numbers {
  margin-right: 1rem;
  display: flex;
  text-align: center;
}

.pagination-numbers p {
  margin-bottom: 0;
}

.pagination-numbers ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  column-gap: 0.4rem;
  row-gap: 0.15rem;
  font-weight: 700;
  font-size: 1.1rem;
  cursor: pointer;
  margin-left: 1rem;
}

.pagination-numbers ul li:hover {
  color: orange;
}

.pagination-size {
  display: flex;
  text-align: center;
}

.pagination-size p {
  margin-bottom: 0;
}

.pagination-size ul {
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  font-weight: 700;
  font-size: 1.1rem;
  cursor: pointer;
}

.pagination-size ul li:hover {
  color: orange;
}

.btn-sm {
  padding: 0.2rem 1rem;
  border: 0px;
  border-radius: 15px;
  font-size: 0.8rem;
  font-weight: 800;
  transition: all 0.3s ease-in-out;
}

.set-password-form {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
}

.input_box {
  flex-basis: 350px;
}

.field-description span {
  font-weight: 700;
  color: red;
}

.discount {
  flex-basis: 200px;
  text-align: left;
}

.refUserForm {
  display: flex;
  justify-content: flex-start;
  gap: 2rem;
}

#refUserForm {
  justify-content: center;
  align-items: flex-start;
}

.ref-user__data {
  display: flex;
  gap: 1rem;
  align-items: flex-end;
}

.ref-user__data h3,
.ref-user__data p {
  margin-bottom: 0;
}

.ref-user__data h3 {
  font-size: 1.1rem;
}

.date-picker {
  display: flex;
  gap: 2rem;
}

.adminOrders__row-inner {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-basis: 1;
}

.adminProducts__row-inner {
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-basis: 1;
}

.order_icons {
  display: flex;
  flex-direction: row;
  gap: 0.6rem;
  width: 150px;
}

.order_icon {
  max-height: 20px;
  height: 20px;
}

.order_icon svg {
  max-height: 100%;
  height: 100%;
  width: 100%;
}

.ordersTable_id {
  text-align: left;
  width: 65px;
}

.ordersTable_client {
  text-align: left;
  width: 300px;
}

.ordersTable_date {
  text-align: left;
  width: 115px;
}

.ordersTable_value {
  text-align: left;
  width: 115px;
}

.ordersTable_status-names {
  text-align: right;
  width: 120px;
}

.ordersTable_status {
  text-align: left;
  width: 140px;
}

.ordersTable_status div {
  display: flex;
  justify-content: flex-end;
}

.ordersTable_status-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  text-align: left;
  width: 265px;
}

.adminOrders__searchbar {
  display: flex;
  gap: 1rem;
  max-width: 500px;
  width: 100%;
  margin-bottom: 2rem;
}

@media screen and (max-width: 1350px) {
  .adminOrders__row {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .adminOrders__row-inner {
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
  }

  .ordersTable_value,
  .ordersTable_date,
  .ordersTable_id,
  .ordersTable_client,
  .ordersTable_status-wrapper {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: flex-start;
    text-align: left;
    width: auto;
  }

  .ordersTable_id,
  .order_icons {
    width: 15%;
    min-width: 160px;
  }

  .ordersTable_client,
  .ordersTable_value {
    width: 50%;
    min-width: 510px;
  }

  .ordersTable_date,
  .ordersTable_status-wrapper {
    max-width: 20%;
    min-width: 265px;
    display: flex;
    justify-content: flex-end;
  }

  .ordersTable_value div,
  .ordersTable_date div,
  .ordersTable_id div,
  .ordersTable_client div,
  .ordersTable_status-wrapper div {
    text-align: left;
  }
}

@media screen and (max-width: 1150px) {
  .adminOrders__row {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .adminOrders__row-inner {
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
  }

  .adminProducts__row {
    flex-direction: row;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .adminProducts__row-inner {
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
  }

  .ordersTable_value,
  .ordersTable_date,
  .ordersTable_id,
  .ordersTable_client,
  .ordersTable_status-wrapper {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: flex-start;
    text-align: left;
    width: auto;
  }

  .ordersTable_id,
  .order_icons {
    width: 15%;
    min-width: 110px;
  }

  .ordersTable_client,
  .ordersTable_value {
    width: 50%;
    min-width: 370px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 5px;
  }

  .ordersTable_date,
  .ordersTable_status-wrapper {
    max-width: 20%;
    min-width: 185px;
    display: flex;
    justify-content: flex-end;
  }

  .ordersTable_value div,
  .ordersTable_date div,
  .ordersTable_id div,
  .ordersTable_client div,
  .ordersTable_status-wrapper div {
    text-align: left;
  }
}

@media screen and (max-width: 1215px) {
  .adminOrders__searchbar {
    flex-wrap: wrap;
    max-width: 100%;
  }

  .fieldBox {
    width: 45%;
  }

  .adminOrders__searchbar {
    flex-wrap: wrap;
  }

  .fieldBox input,
  .fieldBox select {
    width: 100%;
  }
  .adminMenu__list {
    gap: 0.5rem;
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 975px) {
  .adminOrders__row {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .adminOrders__row-inner {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    gap: 0.3rem;
  }

  .ordersTable_value,
  .ordersTable_date,
  .ordersTable_id,
  .ordersTable_status-wrapper {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    width: auto;
  }

  .ordersTable_client {
    flex-direction: column;
    font-size: 0.9rem;
  }

  .ordersTable_id,
  .order_icons {
    width: 15%;
    min-width: 110px;
  }

  .ordersTable_client,
  .ordersTable_value {
    width: 50%;
    min-width: 370px;
    margin-right: 5px;
  }

  .ordersTable_client:first-child {
    width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ordersTable_date,
  .ordersTable_status-wrapper {
    max-width: 20%;
    min-width: 185px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .adminOrders__table {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .adminOrders__table a {
    width: 48%;
  }

  .adminProducts__table {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .adminProducts__table a {
    width: 100%;
  }

  .ordersTable_value div,
  .ordersTable_date div,
  .ordersTable_id div,
  .ordersTable_client div,
  .ordersTable_status-wrapper div {
    text-align: left;
  }

  .main {
    width: 100%;
  }
}

@media screen and (max-width: 700px) {
  .adminOrders__table a {
    width: 100%;
  }

  .clientData {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    max-width: 700px;
    margin-top: 2rem;
  }

  .set-status {
    width: 100%;
  }

  .admin-users > .adminOrders__col {
    width: 95%;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .admin-users {
    font-size: 0.9rem;
    overflow: hidden;
  }

  .adminProducts__row {
    width: 100%;
    flex-direction: column;
  }

  .adminProducts__row > .prodName {
    width: 100%;
  }

  .adminProducts__row > .adminOrders__col {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .price-description {
    max-width: 70%;
    text-align: left;
  }
}

.update-price-box {
  background-color: white;
  border: 1px solid red;
  padding: 1rem;
  border-radius: 10px;
  position: absolute;
  z-index: 10;
}
